import { graphql } from 'gatsby';
import SitemapPage from 'gatsby-theme-dettol/src/templates/SitemapPage/SitemapPage';

export default SitemapPage;

export const query = graphql`
  query ($lang: String) {
    languageSelector(lang: { eq: $lang }) {
      ...FragmentLanguageSwitcher
    }
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    menu(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footerNavigation(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    mobileAppPromoBanner(lang: { eq: $lang }) {
      ...FragmentMobileAppPromoBanner
    }
    homepageSettings(lang: { eq: $lang }) {
      ...FragmentHomepageSettings
    }
    articleListing: allUmbracoArticleListingPage(filter: { lang: { eq: $lang } }) {
      nodes {
        id
        link
        name
        titleBold
        titleRegular
      }
    }
    articleDetail: allUmbracoArticle(filter: { lang: { eq: $lang } }) {
      nodes {
        id
        title
        link
      }
    }
    productListing: allUmbracoProductListingPage(filter: { lang: { eq: $lang } }) {
      nodes {
        id
        link
        name
        titleBold
        titleRegular
      }
    }
    productDetail: allUmbracoProduct(filter: { lang: { eq: $lang } }) {
      nodes {
        id
        link
        name
      }
    }
    purposePage: umbracoPurpose(lang: { eq: $lang }) {
      title
      link
    }
    newsletterSignUp(lang: { eq: $lang }) {
      link
      signUpBanner {
        regularTitle
        boldTitle
      }
    }
    faq: umbracoFaq(lang: { eq: $lang }) {
      title
      link
    }
    contactUs(lang: { eq: $lang }) {
      link
      title
    }
    tips: umbracoTips(lang: { eq: $lang }) {
      link
      name
    }
    sitemap(lang: { eq: $lang }) {
      link
      title
      seoMetaDescription
      seoMetaTitle
      seoMetaKeywords
      seoCanonicalUrl
      seoImage
      seoExternalHreflangs {
        key
        value
      }
    }
    allLds(filter: { lang: { eq: $lang } }) {
      nodes {
        title
        link
      }
    }
  }
`;
